// @flow

import React from "react";

export default function IndeterminateCheckbox() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="18" height="18" x="3" y="3" fill="#2F80ED" rx="2" />
      <path stroke="#FFFFFF" strokeWidth="2" d="M6 12 H18" />
    </svg>
  );
}
