// @flow
import React from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Error } from "./styles";

export default function ErrorLine({
  error = "Unknown error occured"
}: {
  error?: string
}) {
  return (
    <Error>
      <InfoOutlineIcon />
      {error}
    </Error>
  );
}
