// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { HeaderSelector as StyledSelector } from "./styles";
import Checkbox from "src/components/Unifize/Checkbox";
import {
  clearTemplateRowSelection,
  toggleTemplateRowSelection
} from "src/actions/workflows";
import {
  allTemplateRowsSelected,
  anyTemplateRowSelected,
  allTemplateIds
} from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  rowSelected: boolean,
  allSelected: boolean,
  allTemplates: Array<number>,
  _clearTemplateRowSelection: Function,
  _toggleTemplateRowSelection: Function,
  children: Node
};

const HeaderSelector = ({
  rowSelected,
  allSelected,
  allTemplates,
  _clearTemplateRowSelection,
  _toggleTemplateRowSelection,
  children
}: Props) => {
  const handleSelect = useCallback(() => {
    if (rowSelected || allSelected) {
      _clearTemplateRowSelection();
    } else {
      allTemplates.forEach(t => _toggleTemplateRowSelection(t));
    }
  }, [rowSelected, allSelected, allTemplates, _clearTemplateRowSelection]);

  return (
    <StyledSelector>
      <Checkbox
        id="selectAllWorkflowTemplate"
        checked={allSelected}
        onChange={handleSelect}
        indeterminate={!allSelected && rowSelected}
      />
      {children}
    </StyledSelector>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  rowSelected: anyTemplateRowSelected(app),
  allSelected: allTemplateRowsSelected(app),
  allTemplates: allTemplateIds(app)
});

export default connect(mapStateToProps, {
  _clearTemplateRowSelection: clearTemplateRowSelection,
  _toggleTemplateRowSelection: toggleTemplateRowSelection
})(HeaderSelector);
