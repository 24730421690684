// @flow

import React from "react";

import ActiveRadioButton from "./ActiveRadioButton";
import ActiveCheckbox from "./ActiveCheckbox";
import AccountTree from "./AccountTree";
import AlarmClock from "./AlarmClock";
import Anchor from "./Anchor";
import AreaChart from "./AreaChart";
import Ascend from "./Ascend";
import Assistant from "./Assistant";
import ActiveQuickFilter from "./ActiveQuickFilter";
import AttachClip from "./AttachClip";
import AttachClip2 from "./AttachClip2";
import BackArrow from "./BackArrow";
import BlueCross from "./BlueCross";
import BlueTick from "./BlueTick";
import Tick from "./Tick";
import Back from "./Back";
import Bell from "./Bell";
import Calendar from "./Calendar";
import Capitalize from "./Capitalize";
import CaretDown from "./CaretDown";
import CaretLeft from "./CaretLeft";
import CaretLeftFilled from "./CaretLeftFilled";
import Change from "./Change";
import CaretRight from "./CaretRight";
import CaretRightFilled from "./CaretRightFilled";
import CircleCheck from "./CircleCheck";
import CircleDown from "./CircleDown";
import Checkbox from "./Checkbox";
import CheckMark from "./CheckMark";
import Checklist from "./Checklist";
import ChecklistEdit from "./ChecklistEdit";
import ChecklistRemove from "./ChecklistRemove";
import CheckSelect from "./CheckSelect";
import Close from "./Close";
import CloseFilter from "./CloseFilter";
import ColumnChart from "./ColumnChart";
import ContactUs from "./ContactUs";
import Copy from "./Copy";
import Conditions from "./Conditions";
import Conversations from "./Conversations";
import ConversationsDark from "./ConversationsDark";
import Contacts from "./Contacts";
import ContactsDark from "./ContactsDark";
import Dashboard from "./Dashboard";
import DashboardDark from "./DashboardDark";
import DateActive from "./DateActive";
import DateActive2 from "./DateActive2";
import Decrement from "./Decrement";
import Decrement2 from "./Decrement2";
import Descend from "./Descend";
import DefaultSort from "./DefaultSort";
import DefaultCheckbox from "./DefaultCheckbox";
import DownIcon from "./DownIcon";
import Detach from "./Detach";
import Delete from "./Delete";
import DefaultRadioButton from "./DefaultRadioButton";
import DeleteTrash from "./DeleteTrash";
import DefaultQuickFilter from "./DefaultQuickFilter";
import DisabledRadioButton from "./DisabledRadioButton";
import Direct from "./Direct";
import DirectDark from "./DirectDark";
import DockMenu from "./DockMenu";
import Down from "./Down";
import Download from "./Download";
import DottedSort from "./DottedSort";
import DropdownCaret from "./DropdownCaret";
import DragIndicator from "./DragIndicator";
import Edit from "./Edit";
import Edit2 from "./Edit2";
import EditPencil from "./EditPencil";
import EveryOne from "./EveryOne";
import ExitFullScreen from "./ExitFullScreen";
import FileArticle from "./FileArticle";
import Forward from "./Forward";
import FileIcons from "./FileIcons";
import FullScreen from "./FullScreen";
import Gear from "./Gear";
import Group from "./Group";
import GroupAvatar from "./GroupAvatar";
import HashMark from "./HashMark";
import Hidden from "./Hidden";
import HollowPlus from "./HollowPlus";
import Home from "./Home";
import HomeDark from "./HomeDark";
import ImageStack from "./ImageStack";
import Increment from "./Increment";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import Info from "./Info";
import Iown from "./Iown";
import Layout from "./Layout";
import Lift from "./Lift";
import LineChart from "./LineChart";
import Lock from "./Lock";
import DueDate from "./DueDate";
import Email from "./Email";
import Favourite from "./Favourite";
import Files from "./Files";
import Layers from "./Layers";
import ParentLayers from "./ParentLayers";
import ListNumbers from "./ListNumbers";
import LogOut from "./LogOut";
import Up from "./Up";
import Mail from "./Mail";
import Manage from "./Manage";
import ManageDark from "./ManageDark";
import Me from "./Me";
import Menu from "./Menu";
import MenuDown from "./MenuDown";
import MessageNotch from "./MessageNotch";
import NoOwner from "./NoOwner";
import NoOwnerActive from "./NoOwnerActive";
import NoResult from "./NoResult";
import Notification from "./Notification";
import Chatroom from "./Chatroom";
import Chart from "./Chart";
import Owner from "./Owner";
import OwnerV2 from "./OwnerV2";
import Participants from "./Participants";
import Org from "./Org";
import OrgDark from "./OrgDark";
import Objective from "./Objective";
import PrimaryRecord from "./PrimaryRecord";
import Privacy from "./Privacy";
import People from "./People";
import Profile from "./Profile";
import Prompts from "./Prompts";
import ProfileCircle from "./ProfileCircle";
import RateChart from "./RateChart";
import ReadStatus from "./ReadStatus";
import Reply from "./Reply";
import Restore from "./Restore";
import Revision from "./Revision";
import Save from "./Save";
import Search from "./Search";
import SearchLense from "./SearchLense";
import Section from "./Section";
import Subsection from "./Subsection";
import Send from "./Send";
import ServerError from "./ServerError";
import ShareLink from "./ShareLink";
import SquarePlus from "./SquarePlus";
import Sort from "./Sort";
import SortBy from "./SortBy";
import SortUp from "./SortUp";
import SortDown from "./SortDown";
import Status from "./Status";
import Star from "./Star";
import UnLock from "./UnLock";
import Refresh from "./Refresh";
import Refresh2 from "./Refresh2";
import Reload from "./Reload";
import RemoveCircle from "./RemoveCircle";
import TaskList from "./TaskList";
import TrendlineChart from "./TrendLineChart";
import TriangleDown from "./TriangleDown";
import Upload from "./Upload";
import UploadCloud from "./UploadCloud";
import CaretUp from "./CaretUp";
import UnifizeLogo from "./UnifizeLogo";
import UrlCopy from "./UrlCopy";
import Unreads from "./Unreads";
import Update from "./Update";
import SaveAs from "./SaveAs";
import VerificationSent from "./VerificationSent";
import VerificationFailed from "./VerificationFailed";
import VisibilityOn from "./VisibilityOn";
import VisibilityOff from "./VisibilityOff";
import Warning from "./Warning";
import Share from "./Share";
import Settings from "./Settings";
import Security from "./Security";
import EditBlue from "./EditBlue";
import NewDelete from "./NewDelete";
import Expansion from "./Expansion";
import CircularArrows from "./CircularArrows";
import SaveAlt from "./SaveAlt";
import CirclePlus from "./CirclePlus";
import ShareDots from "./ShareDots";
import InsertFieldAbove from "./InsertFieldAbove";
import InsertFieldBelow from "./InsertFieldBelow";
import Ellipsis from "./Ellipsis";
import CurrentRevision from "./CurrentRevision";
import CurrentRevisionV2 from "./CurrentRevisionV2";
import Comment from "./Comment";
import CommonChecklist from "./CommonChecklist";
import Archive from "./Archive";
import Unarchive from "./Unarchive";
import Eye from "./Eye";
import Excel from "./Excel";
import RichTextEditorItalic from "./richTextEditor/Italic";
import RichTextEditorBulletList from "./richTextEditor/BulletList";
import RichTextEditorOrderedList from "./richTextEditor/OrderedList";
import RichTextEditorUnderlined from "./richTextEditor/Underlined";
import RichTextEditorUndo from "./richTextEditor/Undo";
import RichTextEditorRedo from "./richTextEditor/Redo";
import RichTextEditorStrikethrough from "./richTextEditor/Strikethrough";
import RichTextEditorBlockQuote from "./richTextEditor/BlockQuote";
import RichTextEditorBold from "./richTextEditor/Bold";
import RichTextEditorAlignCenter from "./richTextEditor/AlignCenter";
import RichTextEditorAlignJustify from "./richTextEditor/AlignJustify";
import RichTextEditorAlignLeft from "./richTextEditor/AlignLeft";
import RichTextEditorAlignRight from "./richTextEditor/AlignRight";
import Sharepoint from "./Sharepoint";
import Preview from "./Preview";
import Edit3 from "./Edit3";
import Download2 from "./Download2";
import MoreMenu from "./MoreMenu";
import MoreMenuFilled from "./MoreMenuFilled";
import ArrowLink from "./ArrowLink";
import RelatedIconV2 from "./RelatedIconV2";
import Records from "./Records";
import Spinner from "./Spinner";

type Props = {
  type: string,
  color?: string,
  filled?: boolean
};

const Icon = ({ type, ...props }: Props) => {
  switch (type) {
    case "download2":
      return <Download2 {...props} />;
    case "edit3":
      return <Edit3 />;
    case "preview":
      return <Preview {...props} />;
    case "richTextEditorItalic":
      return <RichTextEditorItalic {...props} />;
    case "richTextEditorUnderlined":
      return <RichTextEditorUnderlined {...props} />;
    case "richTextEditorBulletList":
      return <RichTextEditorBulletList {...props} />;
    case "richTextEditorOrderedList":
      return <RichTextEditorOrderedList {...props} />;
    case "richTextEditorUndo":
      return <RichTextEditorUndo {...props} />;
    case "richTextEditorRedo":
      return <RichTextEditorRedo {...props} />;
    case "richTextEditorStrikethrough":
      return <RichTextEditorStrikethrough {...props} />;
    case "richTextEditorBlockQuote":
      return <RichTextEditorBlockQuote {...props} />;
    case "richTextEditorBold":
      return <RichTextEditorBold {...props} />;
    case "richTextEditorAlignCenter":
      return <RichTextEditorAlignCenter {...props} />;
    case "richTextEditorAlignJustify":
      return <RichTextEditorAlignJustify {...props} />;
    case "richTextEditorAlignLeft":
      return <RichTextEditorAlignLeft {...props} />;
    case "richTextEditorAlignRight":
      return <RichTextEditorAlignRight {...props} />;

    case "activeRadioButton":
      return <ActiveRadioButton {...props} />;
    case "activeCheckbox":
      return <ActiveCheckbox {...props} />;
    case "accountTree":
      return <AccountTree {...props} />;
    case "alarmClock":
      return <AlarmClock {...props} />;
    case "anchor":
      return <Anchor {...props} />;
    case "area":
      return <AreaChart {...props} />;
    case "archive":
      return <Archive {...props} />;
    case "assistant":
      return <Assistant {...props} />;
    case "attachClip":
      return <AttachClip {...props} />;
    case "attachClip2":
      return <AttachClip2 {...props} />;
    case "back":
      return <Back {...props} />;
    case "bell":
      return <Bell {...props} />;
    case "blueTick":
      return <BlueTick {...props} />;
    case "tick":
      return <Tick {...props} />;
    case "calendar":
      return <Calendar {...props} />;
    case "capitalize":
      return <Capitalize {...props} />;
    case "caretDown":
      return <CaretDown {...props} />;
    case "caretLeft":
      return <CaretLeft {...props} />;
    case "caretLeftFilled":
      return <CaretLeftFilled {...props} />;
    case "caretRight":
      return <CaretRight {...props} />;
    case "caretRightFilled":
      return <CaretRightFilled {...props} />;
    case "caretUp":
      return <CaretUp {...props} />;
    case "circleDown":
      return <CircleDown {...props} />;
    case "change":
      return <Change />;
    case "chart":
      return <Chart />;
    case "chatroom":
      return <Chatroom {...props} />;
    case "checkbox":
      return <Checkbox {...props} />;
    case "checkmark":
      return <CheckMark {...props} />;
    case "checklist":
      return <Checklist {...props} />;
    case "checklistEdit":
      return <ChecklistEdit {...props} />;
    case "checklistRemove":
      return <ChecklistRemove {...props} />;
    case "checkSelect":
      return <CheckSelect {...props} />;
    case "close":
      return <Close {...props} />;
    case "comment":
      return <Comment {...props} />;
    case "commonChecklist":
      return <CommonChecklist {...props} />;
    case "contacts":
      return <Contacts {...props} />;
    case "contactsDark":
      return <ContactsDark {...props} />;
    case "conditions":
      return <Conditions {...props} />;
    case "copy":
      return <Copy {...props} />;
    case "conversations":
      return <Conversations {...props} />;
    case "conversationsDark":
      return <ConversationsDark {...props} />;
    case "currentRevision":
      return <CurrentRevision {...props} />;
    case "currentRevisionV2":
      return <CurrentRevisionV2 {...props} />;
    case "relatedIconV2":
      return <RelatedIconV2 {...props} />;
    case "defaultQuickFilter":
      return <DefaultQuickFilter {...props} />;
    case "defaultCheckbox":
      return <DefaultCheckbox {...props} />;
    case "dashboard":
      return <Dashboard />;
    case "dashboardDark":
      return <DashboardDark />;
    case "dateActive":
      return <DateActive {...props} />;
    case "dateActive2":
      return <DateActive2 {...props} />;
    case "decrement":
      return <Decrement {...props} />;
    case "decrement2":
      return <Decrement2 {...props} />;
    case "detach":
      return <Detach {...props} />;
    case "delete":
      return <Delete {...props} />;
    case "disabledRadioButton":
      return <DisabledRadioButton {...props} />;
    case "directDark":
      return <DirectDark {...props} />;
    case "direct":
      return <Direct {...props} />;
    case "dockMenu":
      return <DockMenu {...props} />;
    case "down":
      return <Down {...props} />;
    case "download":
      return <Download {...props} />;
    case "dottedSort":
      return <DottedSort {...props} />;
    case "dropdownCaret":
      return <DropdownCaret {...props} />;
    case "dragIndicator":
      return <DragIndicator {...props} />;
    case "edit":
      return <Edit {...props} />;
    case "edit2":
      return <Edit2 {...props} />;
    case "editblue":
      return <EditBlue {...props} />;
    case "eye":
      return <Eye {...props} />;
    case "dueDate":
      return <DueDate {...props} />;
    case "email":
      return <Email {...props} />;
    case "excel":
      return <Excel {...props} />;
    case "favourite":
      return <Favourite {...props} />;
    case "fileArticle":
      return <FileArticle {...props} />;
    case "forward":
      return <Forward {...props} />;
    case "fileIcons":
      return <FileIcons {...props} />;
    case "gear":
      return <Gear {...props} />;
    case "hidden":
      return <Hidden {...props} />;
    case "hashmark":
      return <HashMark {...props} />;
    case "files":
      return <Files {...props} />;
    case "group":
      return <Group {...props} />;
    case "groupAvatar":
      return <GroupAvatar {...props} />;
    case "hollowPlus":
      return <HollowPlus {...props} />;
    case "home":
      return <Home />;
    case "homeDark":
      return <HomeDark />;
    case "increment":
      return <Increment {...props} />;
    case "indeterminateCheckbox":
      return <IndeterminateCheckbox {...props} />;
    case "imageStack":
      return <ImageStack {...props} />;
    case "info":
      return <Info {...props} />;
    case "lift":
      return <Lift {...props} />;
    case "line":
      return <LineChart {...props} />;
    case "layout":
      return <Layout {...props} />;
    case "layers":
      return <Layers {...props} />;
    case "parentLayers":
      return <ParentLayers {...props} />;
    case "lock":
      return <Lock {...props} />;
    case "logOut":
      return <LogOut {...props} />;
    case "listNumbers":
      return <ListNumbers {...props} />;
    case "mail":
      return <Mail {...props} />;
    case "manage":
      return <Manage {...props} />;
    case "manageDark":
      return <ManageDark {...props} />;
    case "me":
      return <Me {...props} />;
    case "menu":
      return <Menu {...props} />;
    case "menuDown":
      return <MenuDown {...props} />;
    case "messageNotch":
      return <MessageNotch {...props} />;
    case "noOwner":
      return <NoOwner {...props} />;
    case "noOwnerActive":
      return <NoOwnerActive {...props} />;
    case "notification":
      return <Notification />;
    case "noResult":
      return <NoResult {...props} />;
    case "newDelete":
      return <NewDelete {...props} />;
    case "readStatus":
      return <ReadStatus {...props} />;
    case "removeCircle":
      return <RemoveCircle {...props} />;
    case "owner":
      return <Owner {...props} />;
    case "objective":
      return <Objective {...props} />;
    case "org":
      return <Org {...props} />;
    case "ownerV2":
      return <OwnerV2 {...props} />;
    case "participants":
      return <Participants {...props} />;
    case "orgDark":
      return <OrgDark {...props} />;
    case "primaryRecord":
      return <PrimaryRecord {...props} />;
    case "privacy":
      return <Privacy {...props} />;
    case "people":
      return <People {...props} />;
    case "profile":
      return <Profile />;
    case "prompts":
      return <Prompts {...props} />;
    case "profileCircle":
      return <ProfileCircle />;
    case "rate":
      return <RateChart {...props} />;
    case "refresh":
      return <Refresh {...props} />;
    case "refresh2":
      return <Refresh2 {...props} />;
    case "restore":
      return <Restore {...props} />;
    case "revision":
      return <Revision {...props} />;
    case "circularArrows":
      return <CircularArrows />;
    case "reload":
      return <Reload {...props} />;
    case "reply":
      return <Reply {...props} />;
    case "search":
      return <Search {...props} />;
    case "searchLense":
      return <SearchLense {...props} />;
    case "section":
      return <Section {...props} />;
    case "subSection":
      return <Subsection {...props} />;
    case "send":
      return <Send {...props} />;
    case "settings":
      return <Settings />;
    case "sort":
      return <Sort {...props} />;
    case "sortUp":
      return <SortUp {...props} />;
    case "sortDown":
      return <SortDown {...props} />;
    case "star":
      return <Star {...props} />;
    case "unlock":
      return <UnLock {...props} />;
    case "unarchive":
      return <Unarchive {...props} />;
    case "up":
      return <Up {...props} />;
    case "trendline":
      return <TrendlineChart {...props} />;
    case "tasklist":
      return <TaskList {...props} />;
    case "triangleDown":
      return <TriangleDown {...props} />;
    case "upload":
      return <Upload {...props} />;
    case "uploadCloud":
      return <UploadCloud {...props} />;
    case "unifizeLogo":
      return <UnifizeLogo {...props} />;
    case "urlCopy":
      return <UrlCopy {...props} />;
    case "update":
      return <Update />;
    case "saveAs":
      return <SaveAs {...props} />;
    case "visibilityOn":
      return <VisibilityOn {...props} />;
    case "visibilityOff":
      return <VisibilityOff {...props} />;
    case "verificationSent":
      return <VerificationSent {...props} />;
    case "verificationFailed":
      return <VerificationFailed {...props} />;
    case "warning":
      return <Warning {...props} />;
    case "activeQuickFilter":
      return <ActiveQuickFilter {...props} />;
    case "closeFilter":
      return <CloseFilter {...props} />;
    case "column":
      return <ColumnChart {...props} />;
    case "contactUs":
      return <ContactUs {...props} />;
    case "unreads":
      return <Unreads {...props} />;
    case "iown":
      return <Iown {...props} />;
    case "sortBy":
      return <SortBy {...props} />;
    case "status":
      return <Status {...props} />;
    case "everyOne":
      return <EveryOne {...props} />;
    case "save":
      return <Save {...props} />;
    case "saveAlt":
      return <SaveAlt {...props} />;
    case "serverError":
      return <ServerError {...props} />;
    case "share":
      return <Share />;
    case "shareDots":
      return <ShareDots />;
    case "ascend":
      return <Ascend {...props} />;
    case "descend":
      return <Descend {...props} />;
    case "defaultSort":
      return <DefaultSort {...props} />;
    case "downIcon":
      return <DownIcon {...props} />;
    case "backArrow":
      return <BackArrow {...props} />;
    case "deleteTrash":
      return <DeleteTrash {...props} />;
    case "editPencil":
      return <EditPencil {...props} />;
    case "fullscreen":
      return <FullScreen />;
    case "exitFullScreen":
      return <ExitFullScreen />;
    case "blueCross":
      return <BlueCross {...props} />;
    case "defaultRadioButton":
      return <DefaultRadioButton {...props} />;
    case "shareLink":
      return <ShareLink {...props} />;
    case "squarePlus":
      return <SquarePlus />;
    case "security":
      return <Security />;
    case "circleCheck":
      return <CircleCheck />;
    case "expansion":
      return <Expansion />;
    case "circlePlus":
      return <CirclePlus />;
    case "insertFieldAbove":
      return <InsertFieldAbove {...props} />;
    case "insertFieldBelow":
      return <InsertFieldBelow {...props} />;
    case "ellipsis":
      return <Ellipsis {...props} />;
    case "sharepoint":
      return <Sharepoint {...props} />;
    case "moreMenu":
      return <MoreMenu {...props} />;
    case "moreMenuFilled":
      return <MoreMenuFilled {...props} />;
    case "arrowLink":
      return <ArrowLink {...props} />;
    case "records":
      return <Records {...props} />;
    case "spinner":
      return <Spinner {...props} />;
    default:
      return null;
  }
};

export default Icon;
