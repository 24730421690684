// @flow

import React, { useRef, useEffect } from "react";

import { Checkbox as StyledCheckbox } from "src/styles/input";

type Props = {
  id?: string,
  checked?: boolean,
  disabled?: boolean,
  indeterminate?: boolean
};

const Checkbox = ({ id, checked, disabled, indeterminate }: Props) => {
  const checkboxRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate ?? false;
    }
  });

  return (
    <StyledCheckbox disabled={disabled}>
      <label
        htmlFor={disabled ? null : id}
        tabIndex="0"
        role="checkbox"
        aria-checked={checked}
      >
        <input
          type="checkbox"
          checked={checked}
          id={disabled ? id : null}
          readOnly={indeterminate ? false : true}
          ref={checkboxRef}
        />
        <div />
      </label>
    </StyledCheckbox>
  );
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false
};

export default Checkbox;
