// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import Checkbox from "src/components/Unifize/Checkbox";
import { toggleTemplateRowSelection } from "src/actions/workflows";
import { isTemplateRowSelected } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  roomId: number,
  selected: boolean,
  _toggleTemplateRowSelection: Function
};

const TemplateCheckbox = ({
  roomId,
  selected,
  _toggleTemplateRowSelection
}: Props) => {
  const handleSelect = useCallback(() => {
    _toggleTemplateRowSelection(Number(roomId));
  }, [roomId, _toggleTemplateRowSelection]);

  return (
    <Checkbox
      id={`select${roomId}`}
      checked={selected}
      onChange={handleSelect}
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  selected: isTemplateRowSelected(app, props.roomId)
});

export default connect(mapStateToProps, {
  _toggleTemplateRowSelection: toggleTemplateRowSelection
})(TemplateCheckbox);
