// @flow

import React from "react";

import {
  Header as StyledHeader,
  Button,
  ButtonActive,
  Buttons
} from "./styles";
import Search from "./Search";
import InstantSearch from "./InstantSearch";
import Icon from "src/components/Icon";
import InlineIcon from "src/icons";
import Upload from "src/containers/chatroom/UploadCurrentRoom";
import * as colors from "src/styles/constants/colors";
import UnifizeButton from "src/components/Unifize/Button";

import { iconSize } from "src/styles/constants/size";

type Props = {
  text: string,
  button?: string,
  addItem?: boolean,
  type?: ?string,
  instant?: boolean,
  buttonColor?: string,
  secondary?: boolean,
  secondaryPlaceholder?: string,
  onExportClick?: ?() => void,
  handleChange: Function,
  handleCreate?: Function,
  handleSecondaryAction?: Function
};

const Header = ({
  text,
  button,
  type,
  instant,
  secondary,
  secondaryPlaceholder,
  handleSecondaryAction,
  addItem,
  buttonColor,
  onExportClick = null,
  handleChange,
  handleCreate
}: Props) => (
  <StyledHeader buttonColor={buttonColor}>
    {instant ? (
      <InstantSearch
        search={text}
        handleSearch={handleChange}
        instant={instant}
      />
    ) : (
      <Search search={text} handleSearch={handleChange} instant={instant} />
    )}

    <Buttons>
      {onExportClick ? (
        <UnifizeButton
          variant="uPrimary"
          handleClick={onExportClick}
          isLoading={false}
          textColor="#1581BA"
          border="solid 1px"
          bgColor="white"
          borderRadius="4px"
          width="auto"
        >
          <InlineIcon type="upload" color="#1581BA" />
          Export
        </UnifizeButton>
      ) : null}
      {addItem ? (
        <div>
          {secondary ? (
            <ButtonActive onClick={handleSecondaryAction}>
              <Icon icon="plusCircle" size={iconSize.space_l} />
              {secondaryPlaceholder}
            </ButtonActive>
          ) : null}

          {type === "file" ? (
            <Upload
              location="manage-file-view"
              render={openFileBrowser => (
                <Button onClick={openFileBrowser}>
                  <Icon icon="plusCircle" size={iconSize.space_l} />
                  {button}
                </Button>
              )}
            />
          ) : (
            <Button type="button" onClick={handleCreate}>
              {buttonColor === colors.active ? (
                <Icon icon="plusCircle" size={iconSize.space_l} />
              ) : (
                <InlineIcon type="removeCircle" size={iconSize.space_l} />
              )}
              {button}
            </Button>
          )}
        </div>
      ) : null}
    </Buttons>
  </StyledHeader>
);

Header.defaultProps = {
  type: null,
  instant: false,
  addItem: true,
  button: "",
  buttonColor: colors.active,
  secondaryPlaceholder: "",
  handleSecondaryAction: () => {},
  secondary: false,
  handleCreate: () => {}
};

export default Header;
