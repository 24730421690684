// @flow
import React from "react";
import { connect } from "react-redux";

import { useDisclosure } from "@chakra-ui/react";

import { anyTemplateRowSelected } from "src/reducers";
import type { AppState } from "src/types";
import Header from "src/components/SearchHeader.old";
import ExportModal from "./ExportModal";

type Props = {
  text: string,
  handleSearch: Function,
  handleCreate?: Function,
  rowSelected: boolean
};

const TemplateHeader = ({
  text,
  handleSearch,
  handleCreate,
  rowSelected
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Header
        text={text}
        handleChange={handleSearch}
        handleCreate={handleCreate}
        button="New Process Template"
        instant
        onExportClick={rowSelected ? onOpen : null}
      />
      {isOpen && <ExportModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  rowSelected: anyTemplateRowSelected(app)
});

export default connect(mapStateToProps)(TemplateHeader);
