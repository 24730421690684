// @flow
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";

import { getWorkflowSelectedRows } from "src/reducers";
import type { AppState } from "src/types";
import { exportSelectedProcesses } from "src/api/workflow";

import { TemplateTitle, Templates } from "./styles";
import ExportButton from "./ExportButton";
import ErrorLine from "./ErrorLine";

type Props = {
  isOpen: boolean,
  onClose: () => void,
  templates: Array<number>
};

const ExportModal = ({ isOpen, onClose, templates }: Props) => {
  const allTemplates = useSelector(({ app }) => app.workflow.byId);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  const exportTemplates = async () => {
    setFetching(true);
    try {
      const result = await exportSelectedProcesses(templates);
      setFetching(false);
      const resultJSON = await result.json();

      const blob = new Blob([JSON.stringify(resultJSON, null, 2)], {
        type: "application/transit+json"
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Export${templates.length && templates[0]}_to_${templates.length && templates[templates.length - 1]}.json`
      );
      // $FlowFixMe Optional chaining
      document.body.appendChild(link);
      link.click();
      // $FlowFixMe Optional chaining
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (e) {
      console.error("Error during export:", e);
      setError(String(e));
      setFetching(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={fetching ? () => {} : onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent sx={{ height: "30rem", maxWidth: "38rem" }}>
        <ModalHeader sx={{ backgroundColor: "#F2F4F7" }}>
          Export process configurations
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: "1rem",
            fontSize: 16
          }}
        >
          <span>The following processes are included in the export.</span>
          <Templates>
            {templates.map((t, i) => (
              <TemplateTitle key={`template${t}-${i}`}>
                {allTemplates[t]?.title}
              </TemplateTitle>
            ))}
          </Templates>
          {error ? (
            <ErrorLine />
          ) : (
            <ExportButton onClick={exportTemplates} loading={fetching} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  templates: getWorkflowSelectedRows(app) || []
});

export default connect(mapStateToProps)(ExportModal);
