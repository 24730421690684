import styled from "styled-components";

export const TemplateTitle = styled.span`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #eaecf0;
`;

export const Templates = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.5rem;
  overflow: scroll;
  max-height: 16rem;
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #d92d20;
  font-size: 14px;
  font-weight: bold;
`;
