// @flow

import React from "react";

import Button from "src/components/Unifize/Button";
import * as color from "src/styles/constants/colors";

export default function ExportButton({
  onClick,
  loading
}: {
  onClick: Function,
  loading: boolean
}) {
  return (
    <Button
      variant="uPrimary"
      handleClick={onClick}
      bgColor={color.lightBlue600}
      width="5rem"
      justifySelf="end"
      position="static"
      margin="0 0 0 auto"
      borderRadius="0.25rem"
      isLoading={loading}
    >
      Export
    </Button>
  );
}
